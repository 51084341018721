import { useEffect, useRef, useState } from "react"
import { Helmet } from "react-helmet"
import { BsPerson, BsSuitcaseLg, BsTruck } from "react-icons/bs"
import { FaEye } from "react-icons/fa"
import { FaRegHeart } from "react-icons/fa6"
import { TbTargetArrow } from "react-icons/tb"
import { useNavigate, useSearchParams } from "react-router-dom"
import Footer from "../../components/footer/Footer"
import Header from "../../components/header/Header"
import YouTubePlayer from "../../components/playerVideo/YoutubePlayer"
import ScrollToTopButton from "../home/components/ScrollToTopButton"
export default function EnterpriseScreen() {
    useEffect(() => {
        document.title = "Logplace - A Empresa"
    }, [])
    const mainRef = useRef<HTMLDivElement>(null)
    const missionVisionValues = useRef<HTMLDivElement>(null)
    const navigate = useNavigate()
    const [headerFixed, setHeaderFixed] = useState(false)
    const [isVisible, setIsVisible] = useState(false)

    const [searchParams] = useSearchParams()
    const query = searchParams.get("section")

    useEffect(() => {
        if (query === "missao") {
            if (missionVisionValues.current) {
                scrollToSection()
            }
        }
    }, [])

    const scrollToSection = () => {
        if (missionVisionValues.current) {
            const element = missionVisionValues.current
            const elementPosition =
                element.getBoundingClientRect().top + window.pageYOffset

            const offset = 100
            const offsetPosition = elementPosition - offset

            if (mainRef.current) {
                mainRef.current.scrollTo({
                    top: offsetPosition,
                    behavior: "smooth"
                })
            }
        }
    }

    const headerFixedTrue = () => {
        setHeaderFixed(true)
    }

    const headerFixedFalse = () => {
        setHeaderFixed(false)
    }

    const scrollToTop = () => {
        if (mainRef.current) {
            mainRef.current.scrollTo({ top: 0, behavior: "smooth" })
        }
    }

    return (
        <>
            <Helmet>
                <title>Logplace - A Empresa</title>
                <meta
                    name="description"
                    content="Oferecemos soluções logísticas sob medida para sua operação. Fulfillment, B2B, B2C com inteligência e tecnologia."
                />
                <meta
                    name="keywords"
                    content="logística, fulfillment, B2C, B2B, transporte, soluções logísticas, entregas, e-commerce"
                />
                <meta name="author" content="LogPlace" />
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1.0"
                />
                <meta property="og:title" content="A Empresa" />
                <meta
                    property="og:description"
                    content="Somos líderes em soluções logísticas para o pequeno, médio e-commerce e grandes operações de varejo."
                />
                <meta property="og:url" content="https://logplace.com.br" />
                <meta property="og:type" content="website" />
            </Helmet>
            <ScrollToTopButton show={isVisible} scrollPage={scrollToTop} />
            <div
                ref={mainRef}
                className="relative flex flex-col flex-1 w-full h-screen overflow-auto bg-white xl:overflow-y-auto"
                onScroll={(event: any) => {
                    if (event.currentTarget.scrollTop > 10) {
                        setHeaderFixed(true)
                    } else {
                        setHeaderFixed(false)
                    }

                    if (event.currentTarget.scrollTop > 500) {
                        setIsVisible(true)
                    } else {
                        setIsVisible(false)
                    }
                }}
            >
                <div className="absolute inset-0 w-full bg-gradient-to-b from-white from-5% via-secondary-1 via-30% via-secondary-2 via-35% to-primary to-80% z-0">
                    <Header
                        background={headerFixed ? " " : ""}
                        headerFixedTrue={headerFixedTrue}
                        headerFixedFalse={headerFixedFalse}
                    />
                    {/*<img
                        className="opacity-25 object-cover hidden lg:flex absolute top-0 left-0 right-0 bottom-0  h-[100%] w-[100%] max-h-[48rem]"
                        src={t1}
                        alt="entregador"
                    />*/}
                    <div className="h-[28rem] justify-center items-center flex flex-col md:px-[8rem] xl:px-[28rem] space-y-10">
                        <h1 className="z-20 text-2xl font-bold text-center text-white md:text-3xl xl:text-5xl">
                            Olá, somos a LogPlace
                        </h1>
                        <p className="z-20 text-2xl text-center text-white">
                            Com quase 20 anos de experiência em entregas para
                            100% do território nacional, somos líderes no
                            atendimento ao pequeno, médio e-commerce e gigantes
                            do varejo.
                            <br /> Também traçamos operações complexas de
                            indústria e governo.
                        </p>
                    </div>
                    <div className="h-[75rem] lg:h-[38rem] xl:h-[34rem] flex flex-col space-y-4 lg:space-y-10 bg-white justify-center items-center lg:space-x-5 lg:px-44">
                        <div className="flex justify-center w-1/2">
                            <p className="text-4xl font-medium text-center">
                                Operação logística de excelência para o seu
                                negócio
                            </p>
                        </div>
                        <div className="flex flex-col items-center justify-center w-full space-y-2 lg:flex-row lg:space-x-5">
                            <div className="flex flex-col items-center justify-between p-4 text-center bg-white rounded-sm shadow-lg h-72 w-80 lg:h-80 lg:w-96">
                                <div className="flex items-center justify-center flex-1">
                                    <div className="flex items-center justify-center w-20 h-20 bg-red-200 rounded-full">
                                        <BsTruck size={50} color="#f74747" />
                                    </div>
                                </div>
                                <div className="flex items-end justify-center flex-1">
                                    <p className="text-xl font-medium">
                                        Fulfillment
                                    </p>
                                </div>
                                <div className="flex items-center justify-center flex-2">
                                    <p className="text-sm font-medium">
                                        Fulfillment é o processo logístico e que
                                        permite entregar produtos a clientes no
                                        e-commerce. Ele abrange desde o
                                        processamento do pedido até a separação
                                        no estoque, embalagem, entrega e
                                        pós-venda.
                                    </p>
                                </div>
                            </div>
                            <div className="flex flex-col items-center justify-between p-4 text-center bg-white rounded-sm shadow-lg h-72 w-80 lg:h-80 lg:w-96">
                                <div className="flex items-center justify-center flex-1">
                                    <div className="flex items-center justify-center w-20 h-20 bg-red-200 rounded-full">
                                        <BsPerson size={50} color="#f74747" />
                                    </div>
                                </div>
                                <div className="flex items-end justify-center flex-1">
                                    <p className="text-xl font-medium">B2C</p>
                                </div>
                                <div className="flex items-center justify-center flex-2">
                                    <p className="text-sm font-medium">
                                        O foco principal é a entrega de produtos
                                        diretamente aos consumidores, em vez de
                                        outras empresas. Isso inclui itens
                                        comprados online, em lojas físicas ou
                                        por meio de vários outros canais.
                                    </p>
                                </div>
                            </div>
                            <div className="flex flex-col items-center justify-between p-4 text-center bg-white rounded-sm shadow-lg h-72 w-80 lg:h-80 lg:w-96">
                                <div className="flex items-center justify-center flex-1">
                                    <div className="flex items-center justify-center w-20 h-20 bg-red-200 rounded-full">
                                        <BsSuitcaseLg
                                            size={50}
                                            color="#f74747"
                                        />
                                    </div>
                                </div>
                                <div className="flex items-end justify-center flex-1">
                                    <p className="text-xl font-medium">B2B</p>
                                </div>
                                <div className="flex items-center justify-center flex-2">
                                    <p className="text-sm font-medium">
                                        O principal foco é a entrega de
                                        produtos, suprimentos ou mercadorias de
                                        uma empresa para outra. Isso pode
                                        incluir matérias-primas, produtos
                                        acabados, componentes, vários tipos de
                                        insumos, etc.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="h-[10rem] lg:h-[15rem] xl:h-[48rem] flex flex-col space-y-4 lg:space-y-10 p-4 bg-white justify-center items-center lg:space-x-5 lg:px-44">
                        <h1 className="mt-5 text-4xl font-medium text-center">
                            Um pouco mais sobre nós
                        </h1>
                        <button
                            onClick={() => {
                                window.location.href =
                                    "https://youtu.be/rRxLPHkIT0A"
                            }}
                            className="w-full h-12 bg-white rounded-lg shadow-md md:w-2/3 xl:hidden"
                        >
                            <p>Conheça um pouco mais sobre nós</p>
                        </button>
                        <div className="hidden xl:flex">
                            <YouTubePlayer />
                        </div>
                    </div>
                    <div ref={missionVisionValues}></div>
                    <div className="h-[60rem] lg:h-[38rem] xl:h-[48rem] flex flex-col space-y-4 lg:space-y-10 bg-white justify-center items-center lg:space-x-5 xl:px-16">
                        <div className="flex flex-col lg:flex-row w-[70%] h-[70%] justify-center items-center space-y-2 lg:space-x-6">
                            <div className="flex flex-col items-center w-full p-2 rounded-lg lg:w-1/3 h-4/5 bg-primary justify-evenly">
                                <TbTargetArrow
                                    className="flex-2"
                                    color="white"
                                    size={60}
                                />

                                <h1 className="flex-1 text-4xl font-bold text-white">
                                    Missão
                                </h1>
                                <p className="text-center text-white flex-2 text-md">
                                    Ser a melhor solução em logística e em
                                    transporte B2B, B2C e de produtos de alto
                                    valor do Brasil
                                </p>
                            </div>
                            <div className="flex flex-col items-center w-full p-2 rounded-lg lg:w-1/3 h-4/5 bg-secondary-1 justify-evenly">
                                <FaEye
                                    className="flex-2"
                                    color="white"
                                    size={60}
                                />

                                <h1 className="flex-1 text-4xl font-bold text-white">
                                    Visão
                                </h1>
                                <p className="text-center text-white flex-2 text-md">
                                    Liderar o mercado e ser referência nos
                                    segmentos em que atuamos, gerando resultados
                                    expressivos aos clientes, acionistas e
                                    colaboradores.
                                </p>
                            </div>
                            <div className="flex flex-col items-center w-full p-2 rounded-lg lg:w-1/3 h-4/5 bg-primary justify-evenly">
                                <FaRegHeart
                                    className="flex-2"
                                    color="white"
                                    size={60}
                                />

                                <h1 className="flex-1 text-4xl font-bold text-white">
                                    Valores
                                </h1>
                                <p className="text-center text-white  flex-2 text-md">
                                    Transformar o mercado de logística nacional
                                    com transparência, ética e comprometimento.
                                    Garantindo sempre segurança e
                                    sustentabilidade em nossa trajetória.
                                </p>
                            </div>
                        </div>
                    </div>
                    {/*<div className="h-[30rem] lg:h-[38rem] xl:h-[48rem] p-4 flex flex-col space-y-4 lg:space-y-10 bg-white justify-center items-center lg:space-x-5 lg:px-44">
                        <div className="w-full h-[90%] rounded-lg shadow-xl hidden xl:flex">
                            <button
                                onClick={() =>
                                    handleToNavigate(
                                        "trabalheconosco/colaborador"
                                    )
                                }
                                className="relative flex items-center justify-center flex-1 p-4 overflow-hidden cursor-pointer rounded-s-lg group"
                            >
                                <img
                                    className="absolute top-0 bottom-0 left-0 right-0 object-cover w-full h-full transition-transform duration-300 ease-in-out rounded-s-lg group-hover:scale-110"
                                    src={colaborator}
                                    alt="entregador"
                                />
                                <h1 className="relative z-10 text-3xl font-bold text-white transition-transform duration-300 ease-in-out group-hover:scale-125">
                                    Seja nosso colaborador
                                </h1>
                            </button>
                            <button
                                onClick={() =>
                                    handleToNavigate("trabalheconosco/agregado")
                                }
                                className="relative flex items-center justify-center flex-1 p-4 overflow-hidden cursor-pointer rounded-e-lg group"
                            >
                                <img
                                    className="absolute top-0 bottom-0 left-0 right-0 object-cover w-full h-full transition-transform duration-300 ease-in-out rounded-e-lg group-hover:scale-110"
                                    src={deliveryMan2}
                                    alt="entregador2"
                                />
                                <h1 className="relative z-10 text-3xl font-bold text-white transition-transform duration-300 ease-in-out group-hover:scale-125">
                                    Seja nosso agregado
                                </h1>
                            </button>
                        </div>
                        <button
                            className="relative w-full m-2 h-2/4 group rounded-xl xl:hidden"
                            onClick={() =>
                                handleToNavigate("trabalheconosco/colaborador")
                            }
                        >
                            <img
                                className="absolute top-0 bottom-0 left-0 right-0 object-cover w-full h-full transition-transform duration-300 ease-in-out rounded-lg xl:rounded-s-lg group-hover:scale-110"
                                src={colaborator}
                                alt="entregador"
                            />
                            <h1 className="relative z-10 text-3xl font-bold text-white transition-transform duration-300 ease-in-out group-hover:scale-125">
                                Seja nosso colaborador
                            </h1>
                        </button>
                        <button
                            className="relative w-full m-2 h-2/4 group rounded-xl xl:hidden"
                            onClick={() =>
                                handleToNavigate("trabalheconosco/agregado")
                            }
                        >
                            <img
                                className="absolute top-0 bottom-0 left-0 right-0 object-cover w-full h-full transition-transform duration-300 ease-in-out rounded-lg xl:rounded-e-lg group-hover:scale-110"
                                src={deliveryMan2}
                                alt="entregador2"
                            />
                            <h1 className="relative z-10 text-3xl font-bold text-white transition-transform duration-300 ease-in-out group-hover:scale-125">
                                Seja nosso agregado
                            </h1>
                        </button>
                    </div>*/}
                    <Footer />
                </div>
            </div>
        </>
    )
}
