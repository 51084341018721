import { useNavigate } from "react-router-dom"
import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"
import { Autoplay, Navigation, Pagination } from "swiper/modules"
import { Swiper, SwiperSlide } from "swiper/react"
import Rastreamento_2_0 from "../../assets/Banner Rastreamento LogPlace (1024 x 1366 px).png"
import Rastreamento_3_0 from "../../assets/Banner Rastreamento LogPlace (390 x 844 px).png"
import Rastreamento_1_0 from "../../assets/Banner Rastreamento LogPlace.png"
import Agregado_2_0 from "../../assets/SEJA UM AGREGADO (1024 x 1366 px).png"
import Agregado_1_0 from "../../assets/SEJA UM AGREGADO (1920x850).png"
import Agregado_3_0 from "../../assets/SEJA UM AGREGADO (390 x 844 px).png"
import Servicos_3_0 from "../../assets/Servico1(390 x 844 px).png"
import Servicos_2_0 from "../../assets/Servico2(1024 x 1366 px).png"
import Servicos_1_0 from "../../assets/Servico3(1920 x 1080 px).png"
import "./Caroussel.css"

const SliderTest = () => {
    const navigate = useNavigate()

    const handleClick = (value: String) => {
        navigate("/" + value)
    }

    return (
        <div className="swiper-container">
            <Swiper
                modules={[Navigation, Autoplay, Pagination]}
                navigation={{
                    nextEl: ".custom-next",
                    prevEl: ".custom-prev"
                }}
                autoplay={{ delay: 5000 }}
                loop={true}
                speed={500}
                className="h-auto custom-swiper"
            >
                <SwiperSlide>
                    <a
                        className="w-full h-full shadow-lg cursor-pointer bg-primary shadow-black"
                        onClick={() => handleClick("trabalheconosco/agregado")}
                    >
                        <img
                            className="hidden object-contain w-full h-full xl:block 2xl:object-cover"
                            src={Agregado_1_0}
                            alt="Trabalhador"
                        />
                        <img
                            className="hidden object-contain w-full h-full sm:block xl:hidden"
                            src={Agregado_2_0}
                            alt="Trabalhador2"
                        />
                        <img
                            className="block object-cover w-full h-full sm:hidden"
                            src={Agregado_3_0}
                            alt="Trabalhador3"
                        />
                    </a>
                </SwiperSlide>

                <SwiperSlide>
                    <div
                        className="w-full h-full shadow-lg cursor-pointer bg-primary shadow-black"
                        onClick={() => handleClick("servicos")}
                    >
                        <img
                            className="hidden object-contain w-full h-full xl:block 2xl:object-cover"
                            src={Servicos_1_0}
                            alt="Serviço"
                        />
                        <img
                            className="hidden object-contain w-full h-full sm:block xl:hidden"
                            src={Servicos_2_0}
                            alt="Serviço2"
                        />
                        <img
                            className="block object-cover w-full h-full sm:hidden"
                            src={Servicos_3_0}
                            alt="Serviço3"
                        />
                    </div>
                </SwiperSlide>

                <SwiperSlide>
                    <div
                        className="w-full h-full shadow-lg cursor-pointer bg-primary shadow-black"
                        onClick={() => handleClick("rastreamento")}
                    >
                        <img
                            className="hidden object-contain w-full h-full xl:block 2xl:object-cover"
                            src={Rastreamento_1_0}
                            alt="Rastreamento"
                        />
                        <img
                            className="hidden object-contain w-full h-full sm:block xl:hidden"
                            src={Rastreamento_2_0}
                            alt="Rastreamento2"
                        />
                        <img
                            className="block object-cover w-full h-full sm:hidden"
                            src={Rastreamento_3_0}
                            alt="Rastreamento3"
                        />
                    </div>
                </SwiperSlide>
            </Swiper>

            {/*<button className="bg-white w-14 h-14 custom-prev absolute top-[45%] left-4 rounded-full cursor-pointer z-10 justify-center items-center flex">
                <FaChevronLeft size={25} className="" />
            </button>
            <button className="bg-white w-14 h-14 custom-next absolute top-[45%] right-4 rounded-full cursor-pointer z-10 justify-center items-center flex">
                <FaChevronRight size={25} className="" />
            </button>*/}
        </div>
    )
}

export default SliderTest
