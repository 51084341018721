import { FaFacebookF, FaInstagram, FaLinkedin } from "react-icons/fa"
import { useNavigate } from "react-router-dom"
import logoHeader from "../../assets/logoHeader.png"

interface FooterProps {
    height?: string
}

export default function Footer({ height }: FooterProps) {
    const navigate = useNavigate()

    const handleToNavigate = (value: String, options?: String) => {
        navigate("/" + value + (options ? "/" + options : ""))
    }

    return (
        <div
            className={`flex flex-col bg-primary ${height ? " lg:h-[" + height + "] " : "lg:h-[24rem]"} h-[60rem]  items-center  px-10 py-4 relative bottom-0 w-full`}
        >
            <div className="flex flex-col w-full h-full lg:flex-row">
                <div className="flex-3 mt-2 lg:p-4 lg:mt-0">
                    <div className="bg-white rounded-lg w-72 h-24 justify-center items-center flex">
                        <img className="w-[100%] h-[80%]" src={logoHeader} />
                    </div>
                    <p className="font-bold text-white text-md mt-2">
                        Orgulho em ser Referência. Há mais de 18 anos <br /> no
                        mercado atendendo <br /> indústrias e varejo de forma
                        <br />
                        inteligente
                    </p>
                    <div className="flex flex-row mt-5 space-x-2">
                        <a
                            className="h-14 w-14 rounded-lg justify-center items-center flex bg-transparent hover:bg-secondary-1"
                            href="https://www.facebook.com/logplacebr"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <FaFacebookF size={30} color="#fff" />
                        </a>
                        <a
                            className="h-14 w-14 rounded-lg justify-center items-center flex bg-transparent hover:bg-secondary-1"
                            href="https://www.instagram.com/logplacebr/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <FaInstagram size={30} color="#fff" />
                        </a>
                        <a
                            className="h-14 w-14 rounded-lg justify-center items-center flex bg-transparent hover:bg-secondary-1"
                            href="https://www.linkedin.com/company/logplacebr/?originalSubdomain=br"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <FaLinkedin size={30} color="#fff" />
                        </a>
                    </div>
                </div>
                <div className="flex-1 mt-16 items-start flex-col flex text-white">
                    <a
                        onClick={() => handleToNavigate("aempresa")}
                        className="font-bold text-xl cursor-pointer"
                    >
                        A Empresa
                    </a>
                    <a
                        onClick={() => handleToNavigate("aempresa")}
                        className=" mt-4 cursor-pointer"
                    >
                        Sobre nós
                    </a>
                    <a
                        onClick={() =>
                            handleToNavigate("aempresa", "?section=missao")
                        }
                        className="cursor-pointer"
                    >
                        Missão, Visão e Valores
                    </a>
                    <a
                        onClick={() => handleToNavigate("aempresa")}
                        className="cursor-pointer"
                    >
                        Trabalhe Conosco
                    </a>
                    <a
                        onClick={() => handleToNavigate("aempresa")}
                        className="cursor-pointer"
                    >
                        Seja um agregado
                    </a>
                </div>
                <div className="flex-1 mt-16 items-start flex-col flex text-white">
                    <a
                        onClick={() => handleToNavigate("servicos")}
                        className="cursor-pointer font-bold text-xl"
                    >
                        Nossos Serviços
                    </a>
                    <a
                        onClick={() => handleToNavigate("servicos")}
                        className="cursor-pointer mt-4"
                    >
                        Somos a LogPlace
                    </a>
                    <a
                        onClick={() => handleToNavigate("servicos")}
                        className="cursor-pointer"
                    >
                        Nossos Clientes
                    </a>
                    <a
                        onClick={() => handleToNavigate("servicos")}
                        className="cursor-pointer"
                    >
                        Seja nosso Cliente
                    </a>
                </div>

                <div className="flex-1 mt-16 items-start flex-col flex text-white">
                    <a
                        onClick={() => handleToNavigate("rastreamento")}
                        className="cursor-pointer font-bold text-xl"
                    >
                        Rastreamento
                    </a>
                    <a
                        onClick={() => handleToNavigate("rastreamento")}
                        className="cursor-pointer mt-4"
                    >
                        Rastreio de encomenda
                    </a>
                </div>
            </div>
            <div className="border border-gray-300 w-full h-[1px] rounded-full"></div>
            <div className="w-full h-10 rounded-full justify-center items-center flex">
                <p className="text-white text-xs">
                    © Copyright 2024 - LogPlace
                </p>
            </div>
        </div>
    )
}
