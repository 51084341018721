import axios from "axios"
const TRK: any = process.env.REACT_APP_BASE_URL_TRACK
const TrackingService = {
    getInformation: async (cpfOrCnpj: string, invoiceNumber: string) => {
        const body = {
            notafiscal: {
                cpfCnpjDestinatario: cpfOrCnpj,
                numero: invoiceNumber
            }
        }
        return axios.post(TRK, body)
    }
}

export default TrackingService
