import { format } from "date-fns"
import { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import { useNavigate } from "react-router-dom"
import { toast, ToastContainer } from "react-toastify"
import logoHeader from "../../assets/logoHeader.png"
import Footer from "../../components/footer/Footer"
import { Tracking } from "../../interface/TrackingInterface"
import TrackingService from "../../services/TrackinService"
export default function TrackingScreen() {
    const CH: any = process.env.REACT_APP_KEY_RECAPTCHA
    const [cpfOrCNpj, setCpfOrCnpj] = useState("")
    const [invoiceNumber, setInvoiceNumber] = useState("")
    const [showDetails, setShowDetails] = useState(false)
    const [isHidden, setIsHidden] = useState(true)
    const [trackingInformation, setTrackingInformation] = useState<Tracking>()
    useEffect(() => {
        document.title = "Logplace - Rastreamento"
    }, [])
    const navigate = useNavigate()
    const handleClick = (value: any) => {
        navigate("/" + value)
    }
    const handleNewSearch = () => {
        setShowDetails(false)
        setIsHidden(true)
        setCpfOrCnpj("")
        setInvoiceNumber("")
    }
    const handleConsult = async () => {
        if (!cpfOrCNpj || !invoiceNumber) {
            toast.error("Por favor, preencha todos os campos", {
                style: {
                    backgroundColor: "#f44336",
                    color: "#fff",
                    padding: "16px",
                    borderRadius: "10px",
                    border: "2px solid #D32F2F",
                    fontSize: "16px"
                }
            })
            return
        }
        if (cpfOrCNpj.length < 11 || cpfOrCNpj.length > 14) {
            toast.error(
                "Por favor, preencha o campo de CPF/CNPJ corretamente",
                {
                    style: {
                        backgroundColor: "#f44336",
                        color: "#fff",
                        padding: "16px",
                        borderRadius: "10px",
                        border: "2px solid #D32F2F",
                        fontSize: "16px"
                    }
                }
            )
            return
        }
        try {
            await TrackingService.getInformation(cpfOrCNpj, invoiceNumber)
                .then((response) => {
                    const parse = JSON.parse(response.data.json)
                    setTrackingInformation(parse)
                    setShowDetails(true)
                })
                .catch((error) => {
                    toast.error(
                        "Não foi possível encontrar informações para os dados informados",
                        {
                            style: {
                                backgroundColor: "#f44336",
                                color: "#fff",
                                padding: "16px",
                                borderRadius: "10px",
                                border: "2px solid #D32F2F",
                                fontSize: "16px"
                            }
                        }
                    )
                })
        } catch (error) {
            toast.error("Erro ao obter dados", {
                style: {
                    backgroundColor: "#f44336",
                    color: "#fff",
                    padding: "16px",
                    borderRadius: "10px",
                    border: "2px solid #D32F2F",
                    fontSize: "16px"
                }
            })
        }
    }
    useEffect(() => {
        if (showDetails) {
            setIsHidden(false)
        } else {
            const timer = setTimeout(() => setIsHidden(true), 10000)
            return () => clearTimeout(timer)
        }
    }, [showDetails])
    return (
        <>
            <Helmet>
                <title>Logplace - Rastreamento</title>
                <meta
                    name="description"
                    content="Oferecemos soluções logísticas sob medida para sua operação. Fulfillment, B2B, B2C com inteligência e tecnologia."
                />
                <meta
                    name="keywords"
                    content="logística, fulfillment, B2C, B2B, transporte, soluções logísticas, entregas, e-commerce"
                />
                <meta name="author" content="LogPlace" />
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1.0"
                />
                <meta property="og:title" content="Rastreamento" />
                <meta
                    property="og:description"
                    content="Somos líderes em soluções logísticas para o pequeno, médio e-commerce e grandes operações de varejo."
                />
                <meta property="og:url" content="https://logplace.com.br" />
                <meta property="og:type" content="website" />
            </Helmet>
            <div className="relative flex flex-col flex-1 w-full h-screen overflow-y-auto bg-white">
                <ToastContainer
                    position="bottom-right"
                    autoClose={1500}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    icon={false}
                    limit={1}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                <div className="flex items-center justify-center w-full h-28">
                    <button
                        onClick={() => handleClick("home")}
                        className="flex items-center justify-center h-24 bg-white rounded-lg w-72"
                    >
                        <img className="w-[100%] h-[90%]" src={logoHeader} />
                    </button>
                </div>
                <div
                    className={`px-4 xl:px-44 h-[45rem] lg:h-[50rem] flex flex-row justify-center xl:justify-center w-full  bg-gradient-to-b from-white from-1% via-secondary-1 via-30% via-secondary-2 via-30% to-primary to-80% z-0 ${showDetails ? "justify-center items-center" : ""}`}
                >
                    <div
                        className={`hidden h-[50rem] w-1/2 justify-center items-center  ${
                            showDetails
                                ? "hidden opacity-0 w-0"
                                : "opacity-100 xl:flex"
                        }`}
                    >
                        <p className="text-4xl font-bold text-white">
                            Olá! <br />
                            Veja aqui o status do
                            <br /> envio das suas
                            <br /> encomendas
                        </p>
                    </div>
                    <div
                        className={`h-[50rem] xl:w-2/3 justify-center items-center flex transition-opacity duration-500 ${
                            showDetails
                                ? "opacity-0 pointer-events-none"
                                : "opacity-100"
                        } ${isHidden && !showDetails ? "m-0" : "hidden"}`}
                    >
                        <div className="flex flex-col w-full p-8 bg-white rounded-lg shadow-lg md:w-4/5 h-2/3">
                            <div className="flex items-center justify-center text-justify flex-2 xl:px-24 2xl:px-32">
                                <p className="mt-2 text-lg font-bold text-center text-black">
                                    Digite as informações necessárias para ver o
                                    acompanhamento do seu envio
                                </p>
                            </div>
                            <div className="flex flex-col items-center justify-center space-y-4 flex-2">
                                <input
                                    type="number"
                                    maxLength={14}
                                    value={cpfOrCNpj}
                                    onChange={(e) =>
                                        setCpfOrCnpj(e.target.value)
                                    }
                                    placeholder="Informe o seu CPF/CNPJ:"
                                    className="w-full h-20 p-4 text-black border border-black rounded-lg"
                                />
                                <input
                                    type="number"
                                    maxLength={12}
                                    value={invoiceNumber}
                                    onChange={(e) =>
                                        setInvoiceNumber(e.target.value)
                                    }
                                    placeholder="Informe o número da nota fiscal:"
                                    className="w-full h-20 p-4 text-black border border-black rounded-lg"
                                />
                            </div>
                            <div className="flex items-center justify-center flex-1 space-x-2">
                                <button
                                    onClick={handleConsult}
                                    className="w-full h-20 font-bold text-white rounded-lg bg-secondary-1"
                                >
                                    Consultar
                                </button>
                            </div>
                        </div>
                    </div>
                    <div
                        className={`w-full h-[80rem] lg:h-[42rem] rounded-lg bg-white transition-opacity duration-1000 p-4 space-y-2 ${
                            showDetails
                                ? "opacity-100"
                                : "opacity-0 pointer-events-none hidden"
                        } ${isHidden && showDetails ? "hidden" : ""}`}
                    >
                        {trackingInformation && (
                            <>
                                <div className="flex flex-col p-4 space-y-2 rounded-lg h-2/6 xl:space-y-0 xl:h-1/4 bg-slate-200 justify-evenly lg:flex-row xl:space-x-4">
                                    <div className="lg:w-2/4">
                                        <p>
                                            Número da Nota:{" "}
                                            {
                                                trackingInformation.notafiscal
                                                    .numero
                                            }{" "}
                                            - Série:{" "}
                                            {
                                                trackingInformation.notafiscal
                                                    .serie
                                            }
                                        </p>
                                        <div className="w-full p-2 bg-white rounded-lg">
                                            <p>
                                                Destinatário:{" "}
                                                {
                                                    trackingInformation
                                                        .notafiscal.destinatario
                                                }
                                            </p>
                                            <p>
                                                CPF/CNPJ:{" "}
                                                {
                                                    trackingInformation
                                                        .notafiscal
                                                        .cpfCnpjDestinatario
                                                }
                                            </p>
                                        </div>
                                    </div>
                                    <div className="lg:w-1/4">
                                        <p>
                                            Fornecedor:{" "}
                                            {
                                                trackingInformation.notafiscal
                                                    .cnpjFornecedor
                                            }
                                        </p>
                                        <p>
                                            Emissão:{" "}
                                            {format(
                                                trackingInformation.notafiscal
                                                    .emissao,
                                                "dd/MM/yyyy"
                                            )}
                                        </p>
                                        <p>
                                            Data Limite:{" "}
                                            {format(
                                                trackingInformation.notafiscal
                                                    .datalimite,
                                                "dd/MM/yyyy"
                                            )}
                                        </p>
                                    </div>
                                    <div className="lg:w-1/4">
                                        <button
                                            onClick={handleNewSearch}
                                            className="w-full h-20 font-bold text-white rounded-lg bg-secondary-1"
                                        >
                                            Nova Busca
                                        </button>
                                    </div>
                                </div>
                                <div className="p-4 overflow-y-auto rounded-lg h-4/6 xl:h-3/4 bg-slate-200">
                                    <table className="w-full">
                                        <thead>
                                            <tr className="flex flex-row flex-1">
                                                <th className="text-center flex-3">
                                                    Descrição
                                                </th>
                                                <th className="flex-1 text-center">
                                                    Status
                                                </th>
                                                <th className="flex-1 text-center">
                                                    Data
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {trackingInformation.ocorrencias.map(
                                                (ocorrencia) => (
                                                    <tr
                                                        className="flex flex-1 border-b-2 border-gray-600"
                                                        key={
                                                            ocorrencia.numOcorrencia +
                                                            Math.random()
                                                        }
                                                    >
                                                        <td className="p-2 text-sm flex-3">
                                                            {ocorrencia.descricaoKronos.includes(
                                                                "-"
                                                            )
                                                                ? ocorrencia.descricaoKronos.split(
                                                                      "-"
                                                                  )[0]
                                                                : ocorrencia.descricaoKronos}{" "}
                                                            <br />
                                                            {ocorrencia.descricaoKronos.includes(
                                                                "-"
                                                            )
                                                                ? ocorrencia.descricaoKronos.split(
                                                                      "-"
                                                                  )[1] +
                                                                  " " +
                                                                  ocorrencia.descricaoKronos.split(
                                                                      "-"
                                                                  )[2]
                                                                : ""}
                                                        </td>
                                                        <td className="flex-1 p-2 font-semibold text-center">
                                                            {ocorrencia.status}
                                                        </td>
                                                        <td className="flex-1 text-sm text-center">
                                                            {format(
                                                                ocorrencia.momento,
                                                                "dd/MM/yyyy hh:mm:ss"
                                                            )}
                                                        </td>
                                                    </tr>
                                                )
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </>
                        )}
                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}
